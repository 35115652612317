import React, {ChangeEvent, useEffect, useState,useRef} from 'react'
import { View } from '../../../app/components/recent_learnings/view'
import { Announcement_view } from '../../../app/components/previous_announcement/view'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useAuth} from '../../../app/modules/auth'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import Marquee from "react-fast-marquee";
import FsLightbox from "fslightbox-react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import toast, { Toaster } from 'react-hot-toast';
import './pop.css';
const Dashboard: React.FC = () => {

  const {currentUser, logout} = useAuth()
  const isOpenstatus = (data:any) => {
    setOpen(false);
  }
  const [selectedID, setSelectedID] = useState(null);
  const [open, setOpen] = useState(false);
  const [open_model, setOpenModel] = useState('');
  const  tempDate = new Date();
  //const  date:any = tempDate.getFullYear() + '-' +'0'+ (tempDate.getMonth()+1) + '-' + tempDate.getDate();
  const month:number=  Number(tempDate.getMonth()+1);
  const value:any = month > 9 ? (month):('0'+month);
  const value2:any = tempDate.getDate() > 9 ? (tempDate.getDate()):('0'+tempDate.getDate());
  
  const  date = tempDate.getFullYear() + '-' + value + '-' + value2; 
  const API_URL = process.env.REACT_APP_API_URL
  const [items, setItems] = useState([]);
  const [leadtype, setLeadType] = useState('Domestic');
  const [from_date, setFromDate] = useState<any>('');
  const [to_date, setToDate] = useState<any>('');
  const [upcomming, setUpcomming] = useState('');
  const [visit_id, setVisitId] = useState('');
  const [customer_type, setCustomerType] = useState('');
  const [customer_status, setCustomerStatus] = useState('');
  const [all, setAll] = useState('');
  const [search_username, setSearchUsername] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [dateformat, setDateFormat] = useState('all');


  const [checkboxes, setCheckboxes] = useState<any>([]);

  const handleCheckboxChange = (value:any) => {
    if (checkboxes.includes(value)) {
      setCheckboxes(checkboxes.filter((box:any) => box !== value));
    } else {
      setCheckboxes([...checkboxes, value]);
    }
  };

  const handleSelectAllChange = (e:any) => {
    if (e.target.checked) {
      const allValues = checkboxData.map((data) => data.value);
      setCheckboxes(allValues);
    } else {
      setCheckboxes([]);
    }
  };
  const checkboxData = [
    { value: '', label: '' },
  ];
  const check1 = [
    { value: 'checkbox2', label: ' ' },
  ];
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const handleToggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  
  const [toggler, setToggler] = useState(false);
  const [tick, set_tick] = useState<any>(false);
  const [new_tick, setnew_tick] = useState<any>(true);
 
  const handleClose = () => {
    set_tick(true);
    setnew_tick(false);
    };
   
    const [showImg, setShowImg] = useState(false);
    const [showImg_1, setShowImg_1] = useState(false);
    const [showImg_2, setShowImg_2] = useState(false);
    const [showImg_3, setShowImg_3] = useState(false);
    const [showGif, setShowGif] = useState(false);

    const timeout = setTimeout(() => {
      setShowGif(false);
    }, 4000);
    const [showGif_1, setShowGif_1] = useState(false);

    const timeout_1 = setTimeout(() => {
      setShowGif_1(false);
    }, 4000);
    const [showGif_2, setShowGif_2] = useState(false);

    const timeout_2 = setTimeout(() => {
      setShowGif_2(false);
    }, 4000);
    const [showGif_4, setShowGif_4] = useState(false);

    const timeout_4 = setTimeout(() => {
      setShowGif_4(false);
    }, 4000);
   
    const [courselist, setCourselist] = useState<any>([]);
    const [lessonslist, setLessonlist] = useState<any>([]);
    const [coursecounts, setCourse] = useState<any>(0);
    const [topcoursecounts, setTopCourse] = useState<any>(0);
    const [lesson, setLesson] = useState<any>([]);
    const [membercount, setmember] = useState<any>([]);
    const [membersubcount, setmembersub] = useState<any>([]);
    const [videourl, setVideoUrl] = useState<any>([]);
    const [cur_pwd_opn, setcur_pwd_opn] = useState<any>(false);
    const [cur_pwd_cls, setcur_pwd_cls] = useState<any>(true);
  
    const [lessonlist, setlessonlist] = useState<any>([]);
    const [youtube_url, setYoutubeUrl] = useState<any>('');
    const [likelist, setLikelist] = useState<any>(0);
    const [futurecourse, setFutureCourse] = useState<any>(0);
    const [eventslist, setEventslist] = useState<any>([]);
    const [events, setEvents] = useState<any>([]);
    const [wiselist, setWiseList] = useState<any>(0);
    const [like, setLike] = useState(0);
    const [view, setView] = useState(0);
    const [wiselistcount, setWiselist] = useState<any>(0);
    const [top, setTop] = useState<any>([]);


    const [selectedCategoryId, setSelectedCategoryId] = useState(""); // State for selected category
    const [selectedCourseId, setSelectedCourseId] = useState("");     // State for selected course
    const [selectedLessonId, setSelectedLessonId] = useState("");     // State for selected lesson
    const [selectedClusterId, setSelectedClusterId] = useState(""); 


    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState<{ __html: string } | null>(null);
  
    const handlePopup = (message:any) => {
      setPopupContent({ __html: message });
      setShowPopup(true);
      // setTimeout(() => setShowPopup(false), 5000); // Close popup after 3 seconds (adjust as needed)
      setShowPopup(true);
    };
    
    const closePopup = () => {
      setShowPopup(false);
    };
    const getAnnouncementList = () => {
      const URL = `${API_URL}/member_announcement_list`;
      axios({
        method: 'get',
        url: URL,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        },
      }).then((res) => {
  
        setItems(res.data.data);
        // setStartDate(res.data.data.from_date);
        // setTillDate(res.data.data.to_date);
      }).catch(error => {
        console.error('Error fetching chapter list:', error);
      });
    }
    const getTopCourseList = () => {
      const URL = `${API_URL}/top_course_member`;
      axios({
        method: 'get',
        url: URL,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        },
      }).then((res) => {
    
        setTop(res.data.data);
          setTopCourse(res.data.count)
      
        
      }).catch(error => {
        console.error('Error fetching chapter list:', error);
      });
    }
  
    // const getCourseList = () => {
    //   const URL = `${API_URL}/member_course_dash`;
    //   axios({
    //     method: 'put',
    //     url: URL,
    //     withCredentials: false,
    //     headers: {
    //       'Access-Control-Allow-Origin': '*',
    //       'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    //       'Authorization': `Bearer ${sessionStorage.getItem("token")}`
    //     },
    //   }).then((res) => {
    
    //     setCourselist(res.data.data);
    //        setCourse(res.data.count)
    //       //  setLikeCourseId(res.data.data.liked_course_id)
    //   }).catch(error => {
    //     console.error('Error fetching chapter list:', error);
    //   });
    // }

    const getCourseList = (asc_id: any = null, desc_id: any = sortingOptionsdesc) => {
      const URL = `${API_URL}/member_course_dash`;
      axios({
        method: 'put',
        url: URL,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        },
        data:{asc_id:asc_id,desc_id:desc_id}
      }).then((res) => {
    
        setCourselist(res.data.data);
           setCourse(res.data.count);
          //  setCourseFilter(res.data.count);
      }).catch(error => {
        console.error('Error fetching chapter list:', error);
      });
    }
    const getLessonsList = (asc_id: any = null, desc_id: any = sortingOptionsdesc) => {
      const URL = `${API_URL}/member_lesson_dash`;
      axios({
        method: 'put',
        url: URL,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        },
        data:{asc_id:asc_id,desc_id:desc_id}
      }).then((res) => {
    
        
        setLessonlist(res.data.data);
          //  setCourse(res.data.count);
          //  setCourseFilter(res.data.count);
      }).catch(error => {
        console.error('Error fetching chapter list:', error);
      });
    }
    const getmemberList = () => {
      const URL = `${API_URL}/member_all_member`;
      axios({
        method: 'put',
        url: URL,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        },
      }).then((res) => {
    
      
          setmember(res.data.count)
          // setLikeCourseId(res.data.data.liked_course_id)
        
      }).catch(error => {
        console.error('Error fetching chapter list:', error);
      });
    }
    const getmembersubList = () => {
      const URL = `${API_URL}/member_sub`;
      axios({
        method: 'put',
        url: URL,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        },
      }).then((res) => {
    
      
          setmembersub(res.data.count)
      
        
      }).catch(error => {
        console.error('Error fetching chapter list:', error);
      });
    }
    const getLessonList = () => {
      const URL = `${API_URL}/lesson_list`;
      axios({
        method: 'put',
        url: URL,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        },
      }).then((res) => {
    
        setLesson(res.data.data);
      
        
      }).catch(error => {
        console.error('Error fetching chapter list:', error);
      });
    }
    const AddWiselist = (course_id:any,lesson_id:any,wiselist_id:any)=>{
     
      if(wiselist_id){
      
        setWiselist(wiselistcount-1);
  
      }else{
      
        setWiselist(wiselistcount+1);
      };
  
      const URL = `${process.env.REACT_APP_API_URL}/add_wise_list`
      axios({
      method: 'post',
      url: URL,
      
      headers: {
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
      data:{course_id:course_id,lesson_id:lesson_id,wiselist_id:wiselist_id}
  
      }).then((response) => {
  
          if(response.data.status == 200){
            
              notify('success',response.data.message);
              getFutureCourse();
              getEventsList();
              getCourseList();
              getLessonsList();
              getmemberList();
              getTopCourseList();
          }else{
         
              notify('error',response.data.message);
          }
          
      });
    }
    const getWiseList = () => {
      const URL = `${API_URL}/wise_list`;
      axios({
        method: 'get',
        url: URL,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        },
      }).then((res) => {
    
        
           setWiselist(res.data.count)
      }).catch(error => {
        console.error('Error fetching chapter list:', error);
      });
    }
  
    const getLikeList = () => {
      const URL = `${API_URL}/like_list`;
      axios({
        method: 'get',
        url: URL,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        },
      }).then((res) => {
    
    
           setLikelist(res.data.count)
      }).catch(error => {
        console.error('Error fetching chapter list:', error);
      });
    }
    const getFutureCourse = () => {
      const URL = `${API_URL}/future_course`;
      axios({
        method: 'get',
        url: URL,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        },
      }).then((res) => {
    
    
           setFutureCourse(res.data.count)
      }).catch(error => {
        console.error('Error fetching chapter list:', error);
      });
    }
    const getEventsList = () => {
      const URL = `${API_URL}/member_events_list`;
      axios({
        method: 'get',
        url: URL,
        withCredentials: false,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        },
      }).then((res) => {
    
        setEventslist(res.data.data);
           setEvents(res.data.count)
          //  setLikeCourseId(res.data.data.liked_course_id)
          //  setViewCourseId(res.data.data.view_course_id)
      }).catch(error => {
        console.error('Error fetching chapter list:', error);
      });
    }
  
    const notify = (status:any,data:any) => {
  
      if(status == 'error'){
            toast.error(data, {
              style: {
                borderRadius: '10px',
                // border: '1px solid #FFFAEE',
                padding: '16px',
                color: '#FFFAEE',
                background:"#057091",
                
              },
              iconTheme: {
                primary: '#FFFAEE',
                secondary: '#bf192a',
              },
            }
      );
      }else{
        toast.success(data, {
          style: {
            borderRadius: '10px',
            // border: '1px solid #FFFAEE',
            padding: '16px',
            color: '#ffc700 ',
            background:"#1C2241",
            
          },
          iconTheme: {
            primary: '#FFFAEE',
            secondary: '#ffc700 ',
          },
        }
        );
      }
      
    } 
  
  
    const viewCounts = (course_id:any,lesson_id:any,course_view_id:any)=>{
      if(view == 1){
        setView(0)
      }else{
        setView(1)
      }
      const URL = `${process.env.REACT_APP_API_URL}/add_view_course`
          axios({
          method: 'post',
          url: URL,
          
          headers: {
              'Access-Control-Allow-Origin' : '*',
              'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
              'Authorization': `Bearer ${sessionStorage.getItem("token")}`
          },
          data:{course_id:course_id,lesson_id:lesson_id,course_view_id:course_view_id}
  
          }).then((response) => {
  
            if(response.data.status == 200){
            
            }else{
          
            }
            
        });
    }
  
    const LikeStatus = (l_course_id:any,l_lesson_id:any,like_course_id:any)=>{
  
 
      if(like_course_id){
      
        setLikelist(likelist-1);
  
      }else{
      
        setLikelist(likelist+1);
      };

  
      const URL = `${process.env.REACT_APP_API_URL}/add_like_list`
      axios({
      method: 'post',
      url: URL,
      
      headers: {
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
      data:{course_id:l_course_id,lesson_id:l_lesson_id,like_course_id:like_course_id}
  
      }).then((response) => {
  
          if(response.data.status == 200){
            
              notify('success',response.data.message);
              
              getFutureCourse();
              getCourseList();
              getLessonsList();
              getEventsList();
              getTopCourseList();
          }else{
         
              notify('error',response.data.message);
          }
          
      });
    }
  
    useEffect(()=>{
      getCourseList();
      getLessonsList();
      getmemberList();
      getLessonList();
      getAnnouncementList();
      getLikeList();
      getWiseList();
      getFutureCourse();
      getEventsList();
      getTopCourseList();
      
    },[])
    const [isHovered, setIsHovered] = useState(false);
    const [isHovereds, setIsHovereds] = useState(false);
    const [sortingOptionsasc, setSortingOptionsasc] = useState<any>('asc_id');
    const [sortingOptionsdesc, setSortingOptionsdesc] = useState<any>('desc_id');
    const textwrap: any = {
   
      width: '250px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      position: 'relative',
      cursor: 'pointer',
    };
  
    const tooltipStyle: any = {
      visibility: isHovereds ? 'visible' : 'hidden',
      width: '200px',
      backgroundColor: 'black',
      color: '#fff',
      textAlign: 'center',
      borderRadius: '5px',
      padding: '5px',
      position: 'absolute',
      bottom: '120%',
      left: '50%',
      marginLeft: '-100px',
      zIndex: 1,
      opacity: isHovereds ? 1 : 0,
      transition: 'opacity 0.3s',
    };
  
    const arrowStyle: any = {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: '50%',
      marginLeft: '-5px',
      borderWidth: '5px',
      borderStyle: 'solid',
      borderColor: 'black transparent transparent transparent',
    };
    const textwrapan:any= {
      textAlign: 'left',
      width:'20',
      overflow:'hidden',
      whiteSpace:'nowrap',
      textOverflow:'ellipsis',
   
    }
  const startdate = new Date('2024-03-17');
  const Tilldate = new Date('2024-03-19');
  const diffInDays = moment(Tilldate).diff(moment(startdate), 'days');

  let message:any;
  if (diffInDays === 0) {
      message = "Same Day";
  } else if (diffInDays === 1) {
      message = "1 Day";
  } else {
      message = `${diffInDays} Days`;
  }

  const currentDate:any = new Date();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  useEffect(() => {
    // Function to handle viewport resize
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    // Add event listener for viewport resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  function getBackgroundImage(lesson: any): string {
    if (lesson.thumbnail_image) {
      return lesson.thumbnail_image; // Use lesson.thumbnail_image if available
    } else if (lesson.url_thumbnail_image) {
      // Construct YouTube thumbnail URL using lesson.url_thumbnail_image
      const videoId = getYouTubeVideoId(lesson.url_thumbnail_image);
      return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    } else {
      return ''; // Default empty string or fallback image URL
    }
  }
  
  // Function to extract YouTube video ID from YouTube video URL
  function getYouTubeVideoId(url:any) {
    // Regular expressions to match various YouTube URL formats
    const regexList = [
      /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=))([^&\n\s]+)/,
      /youtu\.be\/([^&\n\s]+)/
    ];
  
    // Loop through regex patterns to find a match
    for (const regex of regexList) {
      const match = url.match(regex);
      if (match) {
        return match[1]; // Return the video ID
      }
    }
  
    return ''; // Default empty string if no match is found
  }
  return(
    <>
    <Toaster position="top-right"/>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-12'> 
              <div className="card pb-4"> 
                <div className="card-header border-0 pt-0 px-2">
                  <div className="card-title">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap">
                      <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column text-start my-0">Dashboard</h1>
                    </div>
                  </div>
                  <div className="card-toolbar  flex-column">
                    <label className='ms-4 fs-6 fw-bold'>Subscription</label>
                    <div className='d-flex align-items-center justify-content-center'>
                      <label className='fs-6 fw-bold text-success'>{currentUser?.validity_from}</label>
                      <label className='fs-6 fw-bold text-dark ms-2 me-2'>to</label>
                      <label className='fs-6 fw-bold' style={{color:"red"}}>{currentUser?.validity_to}</label>
                    </div>
                  </div>
                </div> 
                  <div className="separator border-primary"></div>
                    <div className='row'>
                      <div className='col-lg-12 pt-2'>
                      <div className="card bg-light-primary shadow mb-xl-4 mt-xl-4">
                          <div className="card-body pb-5">
                            <div className="card-title fw-bold text-dark fs-4 mb-3">New Announcement</div>
                            <div className='scroll-y' style={{maxHeight:'100px'}}>
                              {  items?.map((app:any,i:any)=>{
                                return<div className='d-flex flex-column-fluid justfiy-content-start' key={i}>
                                <label>
                                  <span className="badge badge-primary text-warning animation-blink fs-9">New</span>
                                  </label>
                                  {
                                        // app.from_date < currentDate && app.to_date > currentDate ? (
                                          app.highlight == 1 ? (
                                            <label className='ms-4 fs-6 fw-bold badge badge-primary mb-1' style={textwrapan}>
                                            <span onClick={() => handlePopup(app.message)} dangerouslySetInnerHTML={{ __html: app.title_name }} />
                                            </label>
                                          ) : (
                                            <label className='ms-4 fs-6 fw-bold'style={textwrapan}>
                                            <span onClick={() => handlePopup(app.message)} dangerouslySetInnerHTML={{ __html: app.title_name }} />
                                            </label>
                                          )
                                        // ) : (
                                        //   ''
                                        // )
                                      }
                                
                              </div>
                              })}
                            
                            </div>
                            {showPopup && popupContent && (
                              <>
                              <div className="popup-overlay" >
                                <div className="popup-animation">
                                <span className="close-button fs-2hx text-danger" title={'Close'} onClick={closePopup}>&times;</span>
                                  <div dangerouslySetInnerHTML={popupContent} ></div>
                                
                                </div>
                              </div>
                            </>
                            
                              )}
                            {/* {showPopup && (
                               <>
                               <div className="popup-overlay"></div>
                               <div className="popup-animation" dangerouslySetInnerHTML={popupContent}></div>
                              </>
                            )} */}
                      
                          </div>
                        </div>
                      </div>
                      </div>
                      {/* {isMobileView ? (
                        // Mobile view content
                      <div className='row'>
                        <div className='col-lg-3 pt-2'>
                            <div className="card bg-light-primary shadow mb-xl-4 mt-xl-4">
                              <div className="card-body">
                                <div className="card-toolbar text-center">
                                  <div className='row'>
                                    <div className='col-3'>
                                      <i className="fa-solid fa-chalkboard-user fs-3x" style={{color: "#1c2241"}}></i> 
                                    </div>
                                    <div className='col-3'>
                                    <i className="fa-solid fa-laptop-file fs-3x" style={{color: "#1c2241"}}></i> 
                                    </div>
                                    <div className='col-3'>
                                    <i className="fa-regular fa-heart fs-3x" style={{color: "#1c2241"}}></i> 
                                    </div>
                                    <div className='col-3'>
                                    <Link to="/wise_list"><i className="fa-regular fa-circle-check fs-3x" style={{color: "#1c2241"}}></i></Link>
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-3'>
                                      <div className="pt-2 fw-bold text-dark fs-5 text-center d-block">Courses</div>
                                      </div>
                                      <div className='col-3'>
                                      <div className="pt-2 fw-bold text-dark fs-5 text-center d-block">Future</div>
                                      </div>
                                      <div className='col-3'>
                                      <div className="pt-2 fw-bold text-dark fs-5 text-center d-block">Lessons</div>
                                      </div>
                                      <div className='col-3'>
                                      <div className="pt-2 fw-bold text-dark fs-5 text-center d-block">Wishlist</div>
                                      </div>
                                    
                                  </div>  
                                  <div className='row'>
                                    <div className='col-3'>
                                    <div className="text-dark fw-bold fs-3 text-center">{coursecounts}</div>
                                    </div>
                                    <div className='col-3'>
                                    <div className="text-dark fw-bold fs-3 text-center">{futurecourse}</div>
                                    </div>
                                    <div className='col-3'>
                                    <div className="text-dark fw-bold fs-3 text-center">{likelist}</div>
                                    </div>
                                    <div className='col-3'>
                                    <div className="text-dark fw-bold fs-3 text-center">{wiselistcount}</div>
                                    </div>
                                  </div>
                                </div>
                                  
                              </div>
                            </div>
                        </div>
                      </div>
                      ) : (
                        // Web view content
                        <div className='row'>
                        <div className='col-lg-3 pt-2'>
                          <div className="card bg-light-primary shadow mb-xl-4 mt-xl-4">
                            <div className="card-body">
                                <div className="card-toolbar text-center">
                                  <i className="fa-solid fa-chalkboard-user fs-3x" style={{color: "#1c2241"}}></i>
                                </div>
                              <div className='row'>
                                  <div className="pt-2 fw-bold text-dark fs-5 text-center d-block">No of Courses</div>
                              </div>        
                              <div className="text-dark fw-bold fs-3 text-center">{coursecounts}</div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-3 pt-2'>
                          <div className="card bg-light-primary shadow mb-xl-4 mt-xl-4">
                            <div className="card-body">
                                <div className="card-toolbar text-center">
                                  <i className="fa-solid fa-laptop-file fs-3x" style={{color: "#1c2241"}}></i>
                                </div>
                              <div className='row'>
                                  <div className="pt-2 fw-bold text-dark fs-5 text-center d-block">Upcoming Courses</div>
                              </div>        
                              <div className="text-dark fw-bold fs-3 text-center">{futurecourse}</div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-3 pt-2'>
                          <div className="card bg-light-primary shadow mb-xl-4 mt-xl-4">
                            <div className="card-body">
                                <div className="card-toolbar text-center">
                                  <i className="fa-regular fa-heart fs-3x" style={{color: "#1c2241"}}></i>
                                </div>
                              <div className='row'>
                                  <div className="pt-2 fw-bold text-dark fs-5 text-center d-block">Liked Lessons</div>
                              </div>        
                              <div className="text-dark fw-bold fs-3 text-center">{likelist}</div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-3 pt-2'>
                          <div className="card bg-light-primary shadow mb-xl-4 mt-xl-4">
                            <div className="card-body">
                                <div className="card-toolbar text-center">
                                  <Link to="/wise_list"><i className="fa-regular fa-circle-check fs-3x" style={{color: "#1c2241"}}></i></Link>
                                </div>
                              <div className='row'>
                                  <Link to="/wise_list" className="pt-2 fw-bold text-dark fs-5 text-center d-block">Total Wishlist</Link>
                              </div>        
                              <div className="text-dark fw-bold fs-3 text-center">{wiselistcount}</div>
                            </div>
                          </div>
                        </div>
                        </div>
                      )} */}
                       {/* {
                        courselist.length >0?(<>
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column text-start my-0 py-4">New Courses</h1>
                          <div className='row'>
                          { courselist?.filter((f:any)=>{return f.lesson_count >0 && f.ca_id !== 'CAT-1-24'}).map((lesson:any,i:any)=>{
                                return  <div className='col-lg-3 pb-4' key={i}>
                              <div className="card bg-light-primary shadow hoverabl">
                                <div className="card-body">
                                  <div className='d-flex justify-content-center align-items-center'>
                                  <Link to={`/course/${lesson.course_id}`} key={lesson.course_id} onClick={() => {viewCounts(lesson.course_id,lesson.lesson_id,lesson.course_view_id) }} style={{
                                        backgroundImage: `url('${getBackgroundImage(lesson)}')`,
                                      }} className="d-block bgi-no-repeat bgi-size-cover bgi-position-center rounded position-relative h-150px w-250px me-1"
                                      >
                                    <img src="images/play_thumb_1.png" width={25}  className="position-absolute top-50 start-50 translate-middle" alt=""/>
                                    </Link>
                                  </div>
                                    <div className='d-block align-items-center'>
                                      <label className="pt-2 text-center fw-bold text-dark fs-3 d-block mb-3">{lesson.course_name}</label>
                    
                                      {lesson.lesson_list && lesson.lesson_list.length > 0 &&
                                          <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                              {lesson.lesson_list[0].lesson_name}
                                          </label>
                                      }
                                      <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                        <span className="pt-2 badge badge-success text-black fs-7">{lesson.lesson_count} Lessons</span>
                                      </label>
                                      <div className='d-block text-center'>
                                      {
                                      lesson.course_view_id > 0?(<i className="me-6 fa-solid fa-rectangle-list fs-2" style={{color: "red"}} title='Entroll'></i>):('')
                                      }  
                                      {

                                      <i className={
                                        
                                        lesson.event_like_status == 1  ? (' me-6   fs-2 fa-heart fa-solid shadow-txt'):('me-6   fs-2 fa-heart fa-regular')} 
                                        onClick={() => {LikeStatus(lesson.course_id,'',lesson.liked_course_id) }} 
                                        title='Liked' style={{color: "red"}}></i>

                                      }

                                      {
                                          lesson.event_wise_status == 1 ? (
                                              <i 
                                                  className="fa-solid fa-circle-check fs-2"
                                                  title="Remove Wishlist"
                                                  style={{
                                                      color: "red",
                                                      borderRadius: "50%", 
                                                      transition: "color 0.3s", 
                                                  }}
                                                  onClick={() => {AddWiselist(lesson.course_id, '', lesson.wiselist_id) }}
                                              ></i>
                                          ) : (
                                              <i 
                                                  className="fa-solid fa-check fs-2"
                                                  onClick={() => {AddWiselist(lesson.course_id, '', lesson.wiselist_id) }}
                                                  title="Wishlist"
                                                  style={{
                                                      color: "red",
                                                      borderRadius: "50%", 
                                                      transition: "color 0.3s",
                                                  }}
                                                
                                              ></i>
                                          )
                                      }
                                      </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            })}
                          
                          </div>
                        <div className='text-end'>
                        <Link to="/course_view" className='text-dark fw-bold text-hover-primary fs-6'>Read More...</Link>
                        </div>
                        </>):('')
                        } */}
                        {/* {
                          lessonslist.length > 0 ? (
                          <>
                            
                             
                              <div className="card-header border-0 pt-0 px-2">
                                <div className="card-title">
                                  <div className="page-title d-flex flex-column justify-content-center flex-wrap">
                                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column text-start my-0">Recent videos</h1>                                    
                                  </div>
                                </div>
                                <div className="card-toolbar fw-bold fs-4"
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                >Filter
                                <p className={`btn bg-hover-primary text-hover-white me-3 my-1 btn-sm cursor-pointer`}
                                  >
                                  <i className="fa-solid fa-arrow-down-short-wide fs-4 text-primary"></i>
                                </p>
                                
                        
                                {isHovered && (
                                    <div className="card">
                                      <div 
                                        className=" bg-light shadow-lg rounded position-absolute"
                                        style={{
                                          right: '50px', 
                                          top: '10px',   
                                          minWidth: '180px',
                                          zIndex: 1000
                                        }}
                                      >
                                     <div 
                                        className="menu-item fw-bold fs-5" 
                                        style={{
                                          padding: '10px', 
                                          transition: 'background-color 0.3s, color 0.3s',
                                          cursor: 'pointer'
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#edc42f'}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
                                        onClick={() => getLessonsList('asc_id')}
                                      >
                                        <p style={{ margin: 0, color: '#333' }}>Old Lessons</p>
                                      </div>

                                      <div 
                                        className="menu-item fw-bold" 
                                        style={{
                                          padding: '10px', 
                                          transition: 'background-color 0.3s, color 0.3s',
                                          cursor: 'pointer'
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#edc42f'}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
                                        onClick={() => getLessonsList(null, 'desc_id')}
                                      >
                                        <p style={{ margin: 0, color: '#333' }}>Latest Lessons</p>
                                      </div>
                                     </div>
                                    </div>
                                )}
                                </div>
                              </div> 
                              <div className='row'>
                                  { lessonslist
                                      ?.filter((f: any) => f.lesson_count > 0 && f.ca_id !== 'CAT-1-24')
                                      .slice(0, 4)
                                      .map((lesson: any, i: any) => (
                                          <div className='col-lg-3 pb-4' key={i}>
                                              <div className="card bg-light-primary shadow hoverable">
                                                  <div className="card-body">
                                                      <div className='d-flex justify-content-center align-items-center'>
                                                          <Link
                                                              to={`/course/${lesson.course_id}`}
                                                              key={lesson.course_id}
                                                              onClick={() => { viewCounts(lesson.course_id, lesson.lesson_id, lesson.course_view_id) }}
                                                              style={{ backgroundImage: `url('${getBackgroundImage(lesson)}')` }}
                                                              className="d-block bgi-no-repeat bgi-size-cover bgi-position-center rounded position-relative h-150px w-250px me-1"
                                                          >
                                                              <img src="images/play_thumb_1.png" width={25} className="position-absolute top-50 start-50 translate-middle" alt="" />
                                                          </Link>
                                                      </div>
                                                  
                                                      <div className="d-flex flex-column align-items-center justify-content-center">
                                                        <div
                                                          className="d-flex justify-content-center align-items-center"
                                                          onMouseEnter={() => setIsHovereds(true)}
                                                          onMouseLeave={() => setIsHovereds(false)}
                                                          style={{ position: 'relative', display: 'inline-block' }}
                                                        >
                                                          <label className="pt-2 fw-bold text-dark fs-3 d-block mb-3" style={textwrap}>
                                                            {lesson.lesson_name}
                                                          </label>
                                                          <span style={tooltipStyle}>
                                                            {lesson.lesson_name}
                                                            <span style={arrowStyle}></span>
                                                          </span>
                                                        </div>
                                                        <div className='d-block align-items-center'>
                                                          <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                                            {lesson.course_name}
                                                          </label>
                                                          <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                                            <span className="pt-2 badge badge-success text-black fs-7">{lesson.lesson_count} Lessons</span>
                                                          </label>
                                                        </div>
                                                      </div>

                                                  </div>
                                              </div>
                                          </div>
                                      ))
                                  }
                              </div>
                              <div className='text-end'>
                                  <Link to="/lesson_view" className='text-dark fw-bold text-hover-primary fs-6'>Read More...</Link>
                              </div>
                          </>
                            ) : ('')
                        } */}
                        {
                      courselist.length > 0 ? (
                          <>
                              <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column text-start my-0 py-4">New Courses</h1>
                             
                              <div className='row'>
                                  { courselist
                                      ?.filter((f: any) => f.lesson_count > 0 && f.ca_id !== 'CAT-1-24')
                                      // .slice(0, 4) // Slice to get only the first 4 items
                                      .map((lesson: any, i: any) => (
                                          <div className='col-lg-3 pb-4' key={i}>
                                              <div className="card bg-light-primary shadow hoverable">
                                                  <div className="card-body">
                                                      <div className='d-flex justify-content-center align-items-center'>
                                                          <Link
                                                              to={`/course/${lesson.course_id}`}
                                                              key={lesson.course_id}
                                                              onClick={() => { viewCounts(lesson.course_id, lesson.lesson_id, lesson.course_view_id) }}
                                                              style={{ backgroundImage: `url('${getBackgroundImage(lesson)}')` }}
                                                              className="d-block bgi-no-repeat bgi-size-cover bgi-position-center rounded position-relative h-150px w-250px me-1"
                                                          >
                                                              <img src="images/play_thumb_1.png" width={25} className="position-absolute top-50 start-50 translate-middle" alt="" />
                                                          </Link>
                                                      </div>
                                                      <div className='d-block align-items-center'>
                                                      {/* <div
                                                          onMouseEnter={() => setIsHovereds(true)}
                                                          onMouseLeave={() => setIsHovereds(false)}
                                                          style={{ position: 'relative', display: 'inline-block' }}
                                                        >
                                                          <label
                                                            className="pt-2 text-center fw-bold text-dark fs-3 d-block mb-3"
                                                            style={textwrap}
                                                          >
                                                            {lesson.course_name}
                                                          </label>
                                                          <span style={tooltipStyle}>
                                                            {lesson.lesson_name}
                                                            <span style={arrowStyle}></span>
                                                          </span>
                                                        </div> */}
                                                          <label className="pt-2 text-center fw-bold text-dark fs-3 d-block mb-3" style={textwrap}>{lesson.course_name}</label>

                                                          {/* {lesson.lesson_list && lesson.lesson_list.length > 0 &&
                                                              <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                                                  {lesson.lesson_name}
                                                              </label>
                                                          } */}
                                                         
                                                              {/* <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                                                  {lesson.course_name}
                                                              </label> */}
                                                         
                                                          <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                                              <span className="pt-2 badge badge-success text-black fs-7">{lesson.lesson_count} Lessons</span>
                                                          </label>
                                                          <div className='d-block text-center'>
                                                              {lesson.course_view_id > 0 ? (
                                                                  <i className="me-6 fa-solid fa-rectangle-list fs-2" style={{ color: "red" }} title='Enroll'></i>
                                                              ) : ('')}
                                                              <i
                                                                  className={lesson.event_like_status === 1 ? ('me-6 fs-2 fa-heart fa-solid shadow-txt') : ('me-6 fs-2 fa-heart fa-regular')}
                                                                  onClick={() => { LikeStatus(lesson.course_id, '', lesson.liked_course_id) }}
                                                                  title='Liked'
                                                                  style={{ color: "red" }}
                                                              ></i>
                                                              {
                                                                  lesson.event_wise_status === 1 ? (
                                                                      <i
                                                                          className="fa-solid fa-circle-check fs-2"
                                                                          title="Remove Wishlist"
                                                                          style={{ color: "red", borderRadius: "50%", transition: "color 0.3s" }}
                                                                          onClick={() => { AddWiselist(lesson.course_id, '', lesson.wiselist_id) }}
                                                                      ></i>
                                                                  ) : (
                                                                      <i
                                                                          className="fa-solid fa-check fs-2"
                                                                          onClick={() => { AddWiselist(lesson.course_id, '', lesson.wiselist_id) }}
                                                                          title="Wishlist"
                                                                          style={{ color: "red", borderRadius: "50%", transition: "color 0.3s" }}
                                                                      ></i>
                                                                  )
                                                              }
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      ))
                                  }
                              </div>
                              <div className='text-end'>
                                  <Link to="/course_view" className='text-dark fw-bold text-hover-primary fs-6'>Read More...</Link>
                              </div>
                          </>
                            ) : ('')
                        }


                     
                      {
                       eventslist.length >0?(<>
                       <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column text-start my-0 py-4">Events</h1>
                      <div className='row'>
                      { eventslist?.filter((f:any)=>{return f.lesson_count >0})
                      // .slice(0, 4)
                      .map((lesson:any,i:any)=>{
                            return <div className='col-lg-3 pb-4' key={i}>
                                    <div className="card bg-light-primary shadow hoverabl">
                                      <div className="card-body">
                                      
                                        <div className='d-flex justify-content-center align-items-center'>
                                          {/* <Link to={`/course/${lesson.course_id}`} key={lesson.course_id}  style={{backgroundImage: `url('${toAbsoluteUrl('/images/thumbnail_logo.jpg')}')`}} className="d-block bgi-no-repeat bgi-size-cover bgi-position-center rounded position-relative h-150px w-250px me-1"
                                            >
                                          <img src="images/play_thumb_1.png" width={25}  className="position-absolute top-50 start-50 translate-middle" alt=""/>
                                          </Link> */}
                                          <Link to={`/course/${lesson.course_id}`} key={lesson.course_id} onClick={() => {viewCounts(lesson.course_id,lesson.lesson_id,lesson.course_view_id) }} className="d-block bgi-no-repeat bgi-size-cover bgi-position-center rounded position-relative h-150px w-250px me-1"
                                            style={{
                                              backgroundImage: `url('${getBackgroundImage(lesson)}')`,
                                           }} >
                                            <img src="images/play_thumb_1.png" width={25}  className="position-absolute top-50 start-50 translate-middle" alt=""/>
                                          </Link>
                                        </div>
                                          <div className='d-block align-items-center'>
                                            <label className="pt-2 text-center fw-bold text-dark fs-3 d-block mb-3">{lesson.course_name}</label>
                                            {/* <ReactTooltip id={"co_name"+i}place="top" content={lesson.course_name} /> */}
                                            {lesson.lesson_list && lesson.lesson_list.length > 0 &&
                                                <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                                    {lesson.lesson_list[0].lesson_name}
                                                </label>
                                            }
                                            <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                              <span className="pt-2 badge badge-success text-black fs-7">{lesson.lesson_count} Lessons</span>
                                            </label>
                                            <div className='d-block text-center'>
                                              {
                                              lesson.course_view_id > 0?(<i className="me-6 fa-solid fa-rectangle-list fs-2" style={{color: "red"}} title='Entroll'></i>):('')
                                              }  
                                              {

                                              <i className={
                                                
                                                lesson.event_like_status == 1  ? (' me-6   fs-2 fa-heart fa-solid shadow-txt'):('me-6   fs-2 fa-heart fa-regular')} 
                                                onClick={() => {LikeStatus(lesson.course_id,'',lesson.liked_course_id) }} 
                                                title='Liked' style={{color: "red"}}></i>

                                              }


                                              {/* {

                                                  lesson.event_wise_status == 1 ? (<i className={'me-6 fas fs-2 fa-solid fa-check'}
                                                title='Wishlist' style={{color: "red"} } onClick={() => {AddWiselist(lesson.course_id,'',lesson.wiselist_id) }}></i>):(<i className={'fa-plus me-6 fas fa-plus fs-2'}
                                                onClick={() => {AddWiselist(lesson.course_id,'',lesson.wiselist_id) }} title='Wishlist' style={{color: "red"} }></i>)

                                              } */}
                                              {
                                                  lesson.event_wise_status == 1 ? (
                                                      <i 
                                                         className="fa-solid fa-circle-check fs-2"
                                                          title="Remove Wishlist"
                                                          style={{
                                                              color: "red",
                                                              borderRadius: "50%", 
                                                              transition: "color 0.3s", 
                                                          }}
                                                          onClick={() => {AddWiselist(lesson.course_id, '', lesson.wiselist_id) }}
                                                      ></i>
                                                  ) : (
                                                      <i 
                                                         className="fa-solid fa-check fs-2"
                                                          onClick={() => {AddWiselist(lesson.course_id, '', lesson.wiselist_id) }}
                                                          title="Wishlist"
                                                          style={{
                                                              color: "red",
                                                              borderRadius: "50%", 
                                                              transition: "color 0.3s",
                                                          }}
                                                        
                                                      ></i>
                                                  )
                                              }
                                              {/* {
                                                lesson.event_wise_status == 1 ? (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id={`tooltip-${lesson.course_id}`} >
                                                                Remove Wishlist
                                                            </Tooltip>
                                                        }
                                                        style={{ backgroundColor: "black" }}
                                                    >
                                                        <i 
                                                            className="fa-solid fa-circle-check fs-2"
                                                            title="Remove Wishlist"
                                                            style={{
                                                                color: "red", // Set default color to red
                                                                borderRadius: "50%", // Make the icon round
                                                                transition: "color 0.3s", // Add transition for smooth color change
                                                            }}
                                                            onClick={() => {AddWiselist(lesson.course_id, '', lesson.wiselist_id) }}
                                                        ></i>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id={`tooltip-${lesson.course_id}`} style={{ backgroundColor: "black" }}>
                                                                Wishlist
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i 
                                                            className="fa-solid fa-check fs-2"
                                                            title="Wishlist"
                                                            style={{
                                                                color: "red", // Set default color to red
                                                                borderRadius: "50%", // Make the icon round
                                                                transition: "color 0.3s", // Add transition for smooth color change
                                                            }}
                                                            onClick={() => {AddWiselist(lesson.course_id, '', lesson.wiselist_id) }}
                                                        ></i>
                                                    </OverlayTrigger>
                                                )
                                              } */}

                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                      })}
                      
                      </div>
                      <div className='text-end'>
                      <Link to="/even_list" className='text-dark fw-bold text-hover-primary fs-6'>Read More...</Link>
                      </div>
                       </>):('')
                      }
                      

                      {/* {
                       top.length >0?(<>
                       <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column text-start my-0 py-4">Top Courses</h1>
                        <div className='row'>
                        { top?.filter((f:any)=>{return f.lesson_count >0 && f.cat_id != 1})
                        // .slice(0, 4)
                        .map((lesson:any,i:any)=>{
                              return   <div className='col-lg-3 pb-4' key={i}>

                          <div className="card bg-light-primary shadow hoverabl">
                            <div className="card-body">
                              <div className='d-flex justify-content-center align-items-center'>
                                <Link to={`/course/${lesson.course_id}`}  onClick={() => {viewCounts(lesson.course_id,lesson.lesson_id,lesson.course_view_id) }} className="d-block bgi-no-repeat bgi-size-cover bgi-position-center rounded position-relative h-150px w-250px me-1"
                                style={{
                                  backgroundImage: `url('${getBackgroundImage(lesson)}')`,
                               }}
                                  >
                                  <div className="ribbon ribbon-start ribbon-clip">
                                    <div className="ribbon-label text-black">
                                      Top
                                      <span className="ribbon-inner bg-warning"></span>
                                    </div>
                                  </div>
                                  <img src="images/play_thumb_1.png" width={25}  className="position-absolute top-50 start-50 translate-middle" alt=""/>
                                </Link>
                                
                              </div>
                                <div className='d-block align-items-center'>
                                  <label className="pt-2 text-center fw-bold text-dark fs-3 d-block mb-3">{lesson.course_name}</label>
                                  {lesson.lesson_list && lesson.lesson_list.length > 0 &&
                                        <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                            {lesson.lesson_list[0].lesson_name}
                                        </label>
                                    }
                                  <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                  <span className="pt-2 badge badge-primary text-white fs-7">{lesson.lesson_count} Lessons</span>
                                  </label>
                                </div>
                                <div className='d-block text-center'>
                                {
                                lesson.course_view_id > 0?(<i className="me-6 fa-solid fa-rectangle-list fs-2" style={{color: "red"}} title='Entroll'></i>):('')
                                }  
                                {

                                <i className={
                                  
                                  lesson.event_like_status == 1  ? (' me-6   fs-2 fa-heart fa-solid shadow-txt'):('me-6   fs-2 fa-heart fa-regular')} 
                                  onClick={() => {LikeStatus(lesson.course_id,'',lesson.liked_course_id) }} 
                                  title='Liked' style={{color: "red"}}></i>

                                }


                                  {
                                    lesson.event_wise_status == 1 ? (
                                        <i 
                                            className="fa-solid fa-circle-check fs-2"
                                            title="Remove Wishlist"
                                            style={{
                                                color: "red",
                                                borderRadius: "50%", 
                                                transition: "color 0.3s", 
                                            }}
                                            onClick={() => {AddWiselist(lesson.course_id, '', lesson.wiselist_id) }}
                                        ></i>
                                    ) : (
                                        <i 
                                            className="fa-solid fa-check fs-2"
                                            onClick={() => {AddWiselist(lesson.course_id, '', lesson.wiselist_id) }}
                                            title="Wishlist"
                                            style={{
                                                color: "red",
                                                borderRadius: "50%", 
                                                transition: "color 0.3s",
                                            }}
                                          
                                        ></i>
                                    )
                                  }
                                  </div>
                            </div>
                          </div>
                        </div>})}
                      
                        </div>
                        <div className='text-end'>
                            <Link to="/top_course_list" className='text-dark fw-bold text-hover-primary fs-6'>Read More...</Link>
                          </div>
                       </>):('') 
                      }      */}
              </div>
            </div>
          </div>
             
          {open ?(
              <div className= {open ? "modal fade show bg-black bg-opacity-50 d-block":"modal fade show bg-black bg-opacity-50 d-none"} id='kt_modal_add_company' tabIndex={-1} aria-hidden={true}>
                {
                  open_model == 'view' ? (<>
                    <div className='modal-dialog modal-md mt-5'>
                      <div className='modal-content rounded' style={{backgroundColor: "#ffffff"}}>
                        <View isOpen={isOpenstatus} />
                      </div>
                    </div>
                    </>):(<></>)
                }
                {
                  open_model == 'annoucement_view' ? (<>
                    <div className='modal-dialog modal-md mt-5'>
                      <div className='modal-content rounded' style={{backgroundColor: "#ffffff"}}>
                        <Announcement_view isOpen={isOpenstatus} />
                      </div>
                    </div>
                    </>):(<></>)
                }
                
              </div>
            ):(
              <></>
            )
          }
             
    </>
  
  )
}

export {Dashboard}



