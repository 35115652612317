import {useIntl} from 'react-intl'
import { Link } from 'react-router-dom';
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      
      <div className='app-sidebar-logo px-1 ms-n1' id='kt_app_sidebar_logo'>
      <Link to='/dashboard'>
        
          <img
            alt='Logo'
            src={toAbsoluteUrl('/images/EPC-Logo.png')}
            className='h-60px w-250px app-sidebar-logo-default'
          />
          <>
          {/* <img
            alt='Logo'
            src={toAbsoluteUrl('/images/epc_logo_1.png')}
            className='h-80px w-250px app-sidebar-logo-default'
          /> */}
          </>
       
        <img
          alt='Logo'
          src={toAbsoluteUrl('/images/EPC-Logo.png')}
          className='h-40px w-150px ms-3 app-sidebar-logo-minimize'
        />
      </Link>

    
    </div>
      
    </>
  )
}
