import React, {ChangeEvent, useEffect, useState,useRef} from 'react'
import * as Yup from 'yup'
import axios from 'axios';
import {Formik, useFormik, FormikHelpers, FormikProps, Form, Field,FieldProps,} from 'formik'
import { Link } from 'react-router-dom';
import { Edit } from '../../../../app/components/learnings/edit'
import { Delete } from '../../../../app/components/learnings/delete'
import { View } from '../../../../app/components/recent_learnings/view'
import { Announcement_view } from '../../../../app/components/previous_announcement/view'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import toast, { Toaster } from 'react-hot-toast';
import { Download } from 'lucide-react'
const ProductView: React.FC = () => {


    const isOpenstatus = (data:any) => {
      setOpen(false);
    
    // can get callback data here
    }


  const [selectedID, setSelectedID] = useState(null);
 
  const [open, setOpen] = useState(false);
  const [open_model, setOpenModel] = useState('');
 


  
  const  tempDate = new Date();
  //const  date:any = tempDate.getFullYear() + '-' +'0'+ (tempDate.getMonth()+1) + '-' + tempDate.getDate();
  const month:number=  Number(tempDate.getMonth()+1);
  const value:any = month > 9 ? (month):('0'+month);

  const value2:any = tempDate.getDate() > 9 ? (tempDate.getDate()):('0'+tempDate.getDate());
  
  const  date = tempDate.getFullYear() + '-' + value + '-' + value2; 
  const [visit_id, setVisitId] = useState('');


  const [checkboxes, setCheckboxes] = useState<any>([]);

  const handleSelectAllChange = (e:any) => {
    if (e.target.checked) {
      // Get an array of all checkbox values
      const allValues = checkboxData.map((data) => data.value);
      setCheckboxes(allValues);
    } else {
      setCheckboxes([]);
    }
  };
  const checkboxData = [
    { value: '', label: '' },
    // Add more checkboxes as needed
  ];
  const check1 = [
    { value: 'checkbox2', label: ' ' },
    // Add more checkboxes as needed
  ];
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const handleToggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  const [isSortVisible, setIsSortVisible] = useState(false);
  const handleToggleFilter_1 = () => {
    setIsSortVisible(!isSortVisible);
  };
  const [date_picker, set_date_picker] = useState<any>('');
  
  const [courselist, setCourselist] = useState<any>([]);
  const [coursecount, setCourse] = useState<any>([]);
  const [lesson, setLesson] = useState<any>([]);

// filter

  const [view, setView] = useState(0);
  const API_URL = process.env.REACT_APP_API_URL
  const [category, setCategory] = useState<any>([]);
  const [subCategory, setSubCategory] = useState<any>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategoryId, setSelectedSubcategoryId] = useState("");
  
  
  const getCourseList = (category_id:any = selectedCategoryId,subCategory_id:any = selectedSubCategoryId,asc_id:any=sortingOptionsasc,desc_id:any=sortingOptionsdesc) => {
    const URL = `${API_URL}/product_member_list`;
    axios({
      method: 'put',
      url: URL,
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
      data:{category_id:category_id,subCategory_id:subCategory_id,asc_id:asc_id,desc_id:desc_id}
    }).then((res) => {
  
      setCourselist(res.data.data);
      setCourse(res.data.count);
        //  setCourseFilter(res.data.count);
    }).catch(error => {
      console.error('Error fetching chapter list:', error);
    });
  }

  const getCategoryList = async () => {
    try {
      const response = await axios.get(`${API_URL}/product_category_member`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        }
      });
      setCategory(response.data.data);
    } catch (error) {
      console.error('Error fetching category list:', error);
    }
  };

  const getSubcategoryList = async () => {
    try {
      const response = await axios.get(`${API_URL}/product_subcategory_member`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`
        }
      });
      setSubCategory(response.data.data);
    } catch (error) {
      console.error('Error fetching subcategory list:', error);
    }
  };
  const notify = (status:any,data:any) => {
  
    if(status == 'error'){
          toast.error(data, {
            style: {
              borderRadius: '10px',
              // border: '1px solid #FFFAEE',
              padding: '16px',
              color: '#FFFAEE',
              background:"#057091",
              
            },
            iconTheme: {
              primary: '#FFFAEE',
              secondary: '#bf192a',
            },
          }
    );
    }else{
      toast.success(data, {
        style: {
          borderRadius: '10px',
          // border: '1px solid #FFFAEE',
          padding: '16px',
          color: '#ffc700 ',
          background:"#1C2241",
          
        },
        iconTheme: {
          primary: '#FFFAEE',
          secondary: '#ffc700 ',
        },
      }
      );
    }
    
  } 


  const handleCategoryChange = (e:any) => {
    setSelectedCategoryId(e.target.value);
    setSelectedSubcategoryId("");
  };

  const handleCourseChange = (e:any) => {
    setSelectedSubcategoryId(e.target.value);
  };
  

  const textwrap:any= {
    textAlign: 'center',
    width:'250px',
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
 
  }
 
  useEffect(()=>{
    getCourseList();
    getCategoryList();
    getSubcategoryList();
  },[])

  const [sortingOptionsasc, setSortingOptionsasc] = useState<any>(
    'asc_id'
 
  );
  const [sortingOptionsdesc, setSortingOptionsdesc] = useState<any>(
   
    'desc_id'
  );
console.log(setSortingOptionsasc);
console.log(setSortingOptionsdesc);

function getBackgroundImage(lesson: any): string {
  if (lesson.thumbnail_image) {
    return lesson.thumbnail_image; // Use lesson.thumbnail_image if available
  } else if (lesson.url_thumbnail_image) {
    // Construct YouTube thumbnail URL using lesson.url_thumbnail_image
    const videoId = getYouTubeVideoId(lesson.url_thumbnail_image);
    return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
  } else {
    return ''; // Default empty string or fallback image URL
  }
}

// Function to extract YouTube video ID from YouTube video URL
function getYouTubeVideoId(url:any) {
  // Regular expressions to match various YouTube URL formats
  const regexList = [
    /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=))([^&\n\s]+)/,
    /youtu\.be\/([^&\n\s]+)/
  ];

  // Loop through regex patterns to find a match
  for (const regex of regexList) {
    const match = url.match(regex);
    if (match) {
      return match[1]; // Return the video ID
    }
  }

  return ''; // Default empty string if no match is found
}
// Download handler function
// const handleDownload = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, product: any) => {
//     if (!product.product_document) {
//       e.preventDefault();
//       alert('Document not available for download.');
//     }
//   };
  
//   const handleDownload = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
//     if (!product.product_document) {
//       e.preventDefault();
//       alert('Document not available for download.');
//     }
//   };
  

  return(
    <>
        
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                  <div className='col-12'> 
                  <div className="card px-4 pb-4"> 
                      <div className="card-header border-0 pt-0 px-2">
                        <div className="card-title">
                          <div className="page-title d-flex flex-column justify-content-center flex-wrap">
                            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column text-start my-0">Products</h1>
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item">
                                <Link to="/dashboard" className="pe-2">Home</Link>
                                <i className="fa-solid fa-angles-right"></i>
                            </li>
                            <li className="breadcrumb-item">Product</li>
                          </ul>
                          </div>
                        </div>
                        <div className="card-toolbar">
                            <p className="btn bg-hover-primary text-hover-white me-3 my-1 btn-sm cursor-pointer" data-kt-menu-trigger="hover" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                              <i className="fa-solid fa-arrow-down-short-wide fs-4 text-primary"></i>
                            </p>
                            <div className="menu menu-sub menu-sub-dropdown w-250px w-md-200px menu-state-bg-light-primary" data-kt-menu="true" data-popper-placement="bottom-end">
                              <div className="px-3 py-3">
                                <div className="menu-item">
                                  <p className="menu-link px-5 cursor-pointer" onClick={() => getCourseList(selectedCategoryId, setSelectedSubcategoryId, 'asc_id')}>Ascending</p>
                                </div>
                                <div className="menu-item">
                                  <p className="menu-link px-5 cursor-pointer" onClick={() => getCourseList(selectedCategoryId, setSelectedSubcategoryId, 'desc_id')}>Descending</p> 
                                </div>
                              </div>
                            </div>

                          <p className="btn bg-hover-primary text-hover-white me-3 my-1 btn-sm cursor-pointer" onClick={handleToggleFilter} id="tog_filter">
                              <span><i className="fa fa-filter fs-3 text-primary"></i></span>
                          </p>
                        </div>
                      </div> 
                      <div className="my_filter" style={{ display: isFilterVisible ? 'block' : 'none' }}>
                        <div className='row mt-2 px-2'>                      
                        <div className="col-lg-3">
                            <label className="form-label fw-bold">Category</label>
                            <select 
                            className="form-select form-select-solid" 
                            onChange={handleCategoryChange} 
                            value={selectedCategoryId}
                            >
                            <option value="">-Select category-</option>
                            {category?.map((it:any) => (
                                <option key={it.product_category_id} value={it.product_category_id}>
                                {it.product_category_name}
                                </option>
                            ))}
                            </select>
                        </div>

                        <div className="col-lg-3">
                            <label className="form-label fw-bold">SubCategory</label>
                            <select 
                            className="form-select form-select-solid" 
                            onChange={handleCourseChange} 
                            value={selectedSubCategoryId}
                            >
                            <option value="">-Select SubCategory-</option>
                            {subCategory
                                ?.map((it:any) => (
                                <option key={it.product_subcategory_id} value={it.product_subcategory_id}>
                                    {it.product_subcategory_name}
                                </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-3">
                            <button 
                            type="button" 
                            className="btn btn-primary btn-sm mt-8" 
                            onClick={() => getCourseList()}
                            >
                            Go
                            </button>
                        </div>
                        </div>
                      </div>
                   
                      <div className="pt-2 separator border-primary">
                      </div>
                      <div className='row pt-4'>
                      <div className="page-title d-flex flex-column justify-content-center flex-wrap">
                          <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column text-start my-0 py-4">All Products</h1>
                          <div className='row'>
                         
                          {courselist?.map((product:any, i:any) => (
                            <div className="col-lg-3 pb-4" key={i}>
                                <div className="card bg-light-primary shadow hoverable">
                                <div className="card-body">
                                    <div className="d-flex justify-content-center align-items-center">
                                    <a
                                        href="javascript:void(0);"
                                        style={{
                                        backgroundImage: `url('${product.thumbnail_image || 'images/Document_default.jpg'}')`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        }}
                                        className="d-block bgi-no-repeat rounded position-relative h-150px w-150px me-1"
                                    ></a>
                                    </div>
                                    <div className="d-block align-items-center">
                                    <label
                                        className="pt-2 text-center fw-bold text-dark fs-3 d-block mb-3"
                                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    >
                                        {product.product_subcategory_name}
                                    </label>
                                    <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                        {product.product_category_name}
                                    </label>
                                    <label className="fw-bold text-center text-dark fs-6 mb-3 d-block">
                                        <span className="pt-2 badge badge-success text-black fs-7">{product.product_specification_name}</span>
                                    </label>
                                    <div className="d-block text-center">
                                        {/* <i className="me-6 fa-solid fa-eye fs-2" title="View" style={{ color: "red" }}  onClick={() => handleDirectDownload(product.product_document)}></i> */}
                                        <a 
                                            href={product.product_document} 
                                            download 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            >
                                            <i
                                                className="me-6 fa-solid fa-eye fs-2"
                                                title="View"
                                                style={{ color: "red" }}
                                            ></i>
                                            </a>

                                        {/* <a
                                            href={product.product_document}
                                            download={`${product.product_category_name}_document.pdf`}
                                            // onClick={handleDownload}
                                            className="text-red-600 hover:text-red-800 transition-colors duration-200"
                                        >
                                            <Download className="w-8 h-8" />
                                            <span className="sr-only">Download {product.product_category_name} document</span>
                                        </a> */}
                                       <a 
                                            href={product.product_document} 
                                            download 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            >
                                            <i
                                                className="me-6 fa-solid fa-download fs-2"
                                                title="Download"
                                                style={{ color: "red" }}
                                            ></i>
                                            </a>



                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            ))}
                             
                          </div>
                          
                      </div>
                      </div>
                  </div>
              </div>
            </div>  
            {open ?(
                <div className= {open ? "modal fade show bg-black bg-opacity-50 d-block":"modal fade show bg-black bg-opacity-50 d-none"} id='kt_modal_add_company' tabIndex={-1} aria-hidden={true}>
                
                
                    {
                    open_model == 'view' ? (<>
                        <div className='modal-dialog modal-md mt-5'>
                        <div className='modal-content rounded' style={{backgroundColor: "#ffffff"}}>
                            <View isOpen={isOpenstatus} />
                        </div>
                        </div>
                        </>):(<></>)
                    }
                    {
                    open_model == 'annoucement_view' ? (<>
                        <div className='modal-dialog modal-md mt-5'>
                        <div className='modal-content rounded' style={{backgroundColor: "#ffffff"}}>
                            <Announcement_view isOpen={isOpenstatus} />
                        </div>
                        </div>
                        </>):(<></>)
                    }
                    
                </div>
                ):(
                <></>
                )
            }
             
    </>
  
  )
}

export {ProductView}



